<template>
    <div>
        <div class="container">
            <h3 class="keepUse_h3">TOP商家在用的电商ERP</h3>
            <p class="mid-discript">
                威有ERP涵盖订单、仓储等核心管理模块，满足电商经营的核心管理需求，助力企业提效降本，规模化发展
            </p>
        </div>
        <div class="iconBox" v-if="project.length">
            <div v-for="item in project" :key="item.id">
                <img :src="item.logo" />
            </div>
        </div>
        <div class="merchant-more">
            <a class="more-text" @click.prevent="$router.push('/case')" rel="noopener">
                查看更多
                <svg
                    class="right-arrow"
                    t="1623729006805"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3928"
                    width="32"
                    height="32"
                >
                    <path
                        d="M782.222222 512L307.2 1012.622222c-14.222222 11.377778-39.822222 14.222222-51.2 2.844445-8.533333-8.533333-14.222222-28.444444 2.844444-45.511111l440.888889-455.111112L258.844444 56.888889S241.777778 28.444444 264.533333 14.222222c19.911111-14.222222 34.133333-8.533333 42.666667 0L782.222222 512z"
                        fill="#7781f1"
                        p-id="3929"
                    ></path>
                </svg>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        project: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
.keepUse_h3 {
    width: 528px;
    height: 44px;
    margin: 0 auto;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 38px;
}
.iconBox {
    width: 1200px;
    height: 440px;
    margin: 30px auto 0;
    display: grid;
    grid-template: repeat(4, 1fr) / repeat(8, 1fr);
}
.iconBox img {
    width: 140px;
    height: 80px;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
}
.iconBox img:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
    filter: url("");
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=0);
    -webkit-filter: grayscale(0);
}
.unactive {
}
.show {
    display: block;
}
.none {
    display: none;
}
.merchant-more {
    width: auto;
    text-align: center;
    padding-left: 30px;
}
.merchant-more .more-text {
    display: inline-block;
    width: auto;
    padding-right: 30px;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    line-height: 1;
    font-size: 18px;
    color: #7781f1;
}
svg:not(:root) {
    overflow: hidden;
}
.right-arrow {
    position: absolute;
    right: 10px;
    top: -6px;
    display: inline-block;
    width: 16px;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: all 0.2s;
    color: #7781f1;
}
</style>
