<template>
    <div>
        <div class="container">
            <h3 class="mid-title">威有ERP优势</h3>
        </div>
        <ul class="advantage_ul">
            <li class="advantage_li anmp anmp_content">
                <div class="anmp_animate">
                    <div class="anmp anmp_content_info anmpRerun yi" @mouseleave="ok"></div>
                    <h4 class="advantage_li_h4" style="color: rgb(0, 0, 0)">快</h4>
                </div>
                <p class="advantage_li_p">上门快 上手快 发展快</p>
                <span class="advantage_li_span contray"
                    >全国338个城市覆盖员工近4000，快至29分钟上门，服务团队为您提供针对性的标杆客户的管理经验</span
                >
            </li>
            <li class="advantage_li sec_content">
                <div class="anmp_animate">
                    <div class="sec_content_info anmpRerun er"></div>
                    <h4 class="advantage_li_h4" style="color: rgb(0, 0, 0)">准</h4>
                </div>
                <p class="advantage_li_p">库存准 成本准 分析准</p>
                <span class="advantage_li_span contray">精细到仓到SKU的成本管理,按使用者岗位设置的分析,精准剖析人员绩效</span>
            </li>

            <li class="advantage_li thr_content">
                <div class="anmp_animate">
                    <div class="thr_content_info alive_keep anmpRerun san"></div>
                    <h4 class="advantage_li_h4" style="color: rgb(0, 0, 0)">稳</h4>
                </div>
                <p class="advantage_li_p">系统稳 产品稳</p>
                <span class="advantage_li_span adv_keep wid-span">
                    订单管理、仓储管理、商品管理、分销管理、报表管理、供应链管理、采购管理、库存管理、售后管理、财务管理、客户管理、第三方仓储
                </span>
            </li>
            <li class="advantage_li safe_content">
                <div class="anmp_animate">
                    <div class="safe_content_info safe_con_box anmpRerun si"></div>
                    <h4 class="advantage_li_h4" style="color: rgb(0, 0, 0)">安全</h4>
                </div>
                <p class="advantage_li_p">SAAS系统 传输加密 登录验证</p>
                <span class="advantage_li_span">传输过程中系统数据加密验证身份,分岗位设权限 </span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        ok(e) {
            // let div = e.fromElement;
            // div.style.display = '';
        },
    },
};
</script>
<style lang="less" scoped>
.anmpRerun {
    background-repeat: no-repeat !important;
    background-position-y: 0px;
}
/* 悬停后 */
.yi {
    animation: anyi 0.5s steps(23) forwards;
    @keyframes anyi {
        0% {
            background-position-y: 100%;
        }
        100% {
            background-position-y: 0px;
        }
    }
    &:hover {
        animation: anyi1 0.5s steps(23) forwards;
        @keyframes anyi1 {
            0% {
                background-position-y: 0px;
            }
            100% {
                background-position-y: 100%;
            }
        }
    }
}
.er {
    animation: aner 0.5s steps(23) forwards;
    @keyframes aner {
        0% {
            background-position-y: 100%;
        }
        100% {
            background-position-y: 0px;
        }
    }
    &:hover {
        animation: aner1 0.5s steps(23) forwards;
        @keyframes aner1 {
            0% {
                background-position-y: 0px;
            }
            100% {
                background-position-y: 100%;
            }
        }
    }
}
.san {
    animation: ansan 0.5s steps(23) forwards;
    @keyframes ansan {
        0% {
            background-position-y: 100%;
        }
        100% {
            background-position-y: 0px;
        }
    }
    &:hover {
        animation: ansan1 0.5s steps(23) forwards;
        @keyframes ansan1 {
            0% {
                background-position-y: 0px;
            }
            100% {
                background-position-y: 100%;
            }
        }
    }
}
.si {
    animation: ansi 0.5s steps(23) forwards;
    @keyframes ansi {
        0% {
            background-position-y: 100%;
        }
        100% {
            background-position-y: 0px;
        }
    }
    &:hover {
        animation: ansi1 0.5s steps(23) forwards;
        @keyframes ansi1 {
            0% {
                background-position-y: 0px;
            }
            100% {
                background-position-y: 100%;
            }
        }
    }
}

/* -------------------- */
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
.advantage_ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.advantage_li {
    display: block;
    float: left;
    margin-left: 0;
    width: 25%;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 62px;
    height: 292px;
}
.anmp_animate {
    width: 120px;
    margin: 0 auto;
}
.anmp_animate {
    width: 120px;
    margin: 0 auto;
}
/* 最开始 */

.anmp_content_info {
    background: url(https://iv.okvu.cn/vugw/img/one.png);
    background-size: cover;
}

.anmp_content .anmp_content_info {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
}
.advantage_li_h4 {
    font-size: 24px;
    margin: 0 auto;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 44px;
    margin-top: 36px;
    margin-bottom: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}
.anmp_animate:hover > h4 {
    color: rgb(0, 100, 250) !important;
}
.advantage_li_p {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 160px; */
    height: 16px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 44px;
    opacity: 0.9;
}

.advantage_li_span {
    width: 228px;
    height: 54px;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    text-align: left;
}
.sec_content .sec_content_info {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
}
.sec_content_info {
    background: url(https://iv.okvu.cn/vugw/img/two.png);
    background-size: cover;
}

.thr_content_info {
    background: url(https://iv.okvu.cn/vugw/img/three.png);
    background-size: cover;
}
.alive_keep {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
}
.safe_content_info {
    background: url(https://iv.okvu.cn/vugw/img/four.png);
    background-size: cover;
}
.safe_con_box {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
}
.safe_content h4 {
    width: 48px;
}
.wid-span {
    width: 294px;
}

.mid-title {
    width: 282px;
    height: 44px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 0 auto;
    /* line-height: 62px; */
    margin-top: 111px;
    margin-bottom: 37px;
    text-align: center;
}
</style>
