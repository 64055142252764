<template>
    <div>
        <div class="container">
            <h3 class="mid-title">行业解决方案</h3>
        </div>
        <div class="swiper mySwiper" v-if="industry.children">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in industry.children" :key="item.id">
                    <div class="left">
                        <div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.intro }}</p>
                        </div>
                    </div>
                    <span :data-id="item.id" :data-pid="item.pid" class="sw_link">查看详情</span>
                    <img :src="item.list_image" class="img" />
                </div>
            </div>
            <div class="next" @click="sw.slidePrev()">
                <img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="" />
            </div>
            <div class="prev" @click="sw.slideNext()">
                <img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
    props: {
        industry: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            sw: {},
        };
    },
    mounted() {},
    watch: {
        industry(newVal) {
            let _this = this;
            if (newVal.children) {
                this.$nextTick(() => {
                    this.sw = new Swiper(".mySwiper", {
                        slidesPerView: "auto",
                        centeredSlides: true,
                        spaceBetween: 100,
                        loop: true,
                    });
                    let nodeList = document.querySelectorAll(".sw_link");
                    nodeList.forEach(e => {
                        e.addEventListener("click", function (e) {
                            let id = e.target.getAttribute("data-id");
                            let pid = e.target.getAttribute("data-pid");
                            _this.$router.push(`/Solution?pid=${pid}&id=${id}`);
                        });
                    });
                });
            }
        },
    },
};
</script>
<style scoped>
.mySwiper {
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}
.next {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid white;
    background-color: white;
    overflow: hidden;
    box-sizing: content-box;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    z-index: 9;
    cursor: pointer;
}
.next img {
    position: absolute;
    top: -508px;
    left: -135px;
    width: 950px;
}
.prev {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid white;
    background-color: white;
    overflow: hidden;
    box-sizing: content-box;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    right: 100px;
    z-index: 9;
    cursor: pointer;
}
.prev img {
    position: absolute;
    top: -508px;
    left: -154px;
    width: 950px;
}
.swiper-slide {
    width: 70%;
    border-radius: 20px;
    overflow: hidden;
}
.el-carousel__container {
    overflow: hidden;
}
.el-carousel__item h3 {
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}

.el-carousel__item--card.is-in-stage {
    width: 1000px;
    height: 361px;
    background-size: cover;
    background: #fff;
}
.img {
    width: 50% !important;
    height: 361px !important;
    background-size: cover !important;
    float: right;
}
.left {
    width: 50% !important;
    height: 361px;
    background: rgba(15, 97, 219, 0.03);
    padding: 0 130px 0;
    display: flex;
    align-items: center;
    float: left;
}
.left h3 {
    font-size: 24px;
    font-weight: 600;
    color: #222222;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: left;
}
.left p {
    font-size: 16px;
    line-height: 30px;
    color: #666;
    text-align: justify;
}
.sw_link {
    position: absolute;
    display: block;
    width: 138px;
    height: 40px;
    background: #7781f1;
    color: #fff;
    line-height: 40px;
    border: 1px solid #7781f1;
    top: 280px;
    left: 130px;
    border-radius: 5px;
    cursor: pointer;
}
.sw_link:hover {
    color: #7781f1;
    background: #fff;
}

.el-carousel__item.el-carousel__item.is-in-stage.el-carousel__item--card:not(.is-active) {
    transform: scale(1) !important ;
    transform: translateX(-800px) !important ;
}
.mid-title {
    width: 282px;
    height: 44px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 0 auto;
    /* line-height: 62px; */
    margin-top: 111px;
    margin-bottom: 37px;
    text-align: center;
}
</style>
