<template>
    <div>
        <div class="container">
            <h3 class="mid-title">我们的动态</h3>
        </div>
        <div class="us_bgc_Content">
            <div class="us_bgc"></div>
            <div class="container us">
                <div class="us_contentBox" v-if="article.length">
                    <div class="us_inner us_inner_left">
                        <span class="us_title">{{ article[0].name }}</span>
                        <a href="https://iv.vu818.com/news/" class="arrows_box arro_1" rel="noopener" target="_blank">
                            <div class="arrows">
                                <img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="图标" class="arrActive" />
                            </div>
                        </a>

                        <div class="news-part">
                            <div class="news_content">
                                <a href="https://iv.vu818.com/news/821/" rel="noopener" target="_blank">
                                    <img :src="article[0].children[0].little_image" class="new_img" />
                                </a>
                                <!-- 定位 -->
                                <div class="news_img_title">
                                    <a rel="noopener" target="_blank">
                                        <p>
                                            <a
                                                :title="article[0].children[0].intro"
                                                @click.prevent="$router.push(`/movement?id=${article[0].children[0].art_cat_id}`)"
                                                style="display: inline-block; width: 100%"
                                            >
                                                {{ article[0].children[0].intro }}
                                            </a>
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <div class="a_Box">
                                <ul>
                                    <template v-for="el in article[0].children.slice(1)">
                                        <li>
                                            <a
                                                @click.prevent="$router.push(`/movement?id=${el.art_cat_id}`)"
                                                :title="el.intro"
                                                rel="noopener"
                                            >
                                                {{ el.intro }}
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 分割线 -->
                    <div class="dev_line pull-left"></div>
                    <!-- 中间 -->
                    <div class="us_inner us_inner_cen">
                        <span class="us_title clas_space">{{ article[1].name }}</span>
                        <a href="https://iv.vu818.com/news/class/" class="arrows_box arro_2" rel="noopener" target="_blank">
                            <div class="arrows">
                                <img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="图标" class="arrActive" />
                            </div>
                        </a>
                        <div class="us_inner_cen_abox a_Box1">
                            <ul>
                                <template v-for="el in article[1].children">
                                    <li>
                                        <a
                                            @click.prevent="$router.push(`/movement?id=${el.art_cat_id}`)"
                                            :title="el.intro"
                                            rel="noopener"
                                        >
                                            {{ el.intro }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <!-- 分割线 -->
                    <div class="dev_line pull-left"></div>
                    <!-- 右侧 -->
                    <div class="us_inner us_inner_right">
                        <span class="us_title">{{ article[2].name }}</span>
                        <a href="https://iv.vu818.com/news/case/" class="arrows_box arro_3" rel="noopener" target="_blank">
                            <div class="arrows">
                                <img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="图标" class="arrActive" />
                            </div>
                        </a>
                        <div class="us_inner_cen_abox a_Box1">
                            <ul>
                                <template v-for="el in article[2].children">
                                    <li>
                                        <a
                                            @click.prevent="$router.push(`/movement?id=${el.art_cat_id}`)"
                                            :title="el.intro"
                                            rel="noopener"
                                        >
                                            {{ el.intro }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        article: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        article(val) {
            console.log(val);
        },
    },
};
</script>
<style scoped lang="less">
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    list-style: none;
}
a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        cursor: pointer;
        color: #7781f1 !important;
    }
}
.container ::after {
    display: table;
    content: " ";
}

.us_contentBox {
    padding-top: 58px;
    height: 359px;
    background: #f8fbff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.us_inner_left {
    width: 533px;
    padding-right: 30px;
}
.us_inner {
    box-sizing: border-box;
    float: left;
    position: relative;
    height: 293px;
    z-index: 15;
}
.us_title {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    line-height: 1;
    margin-bottom: 29px;
    text-align: left;
}
.arrows_box {
    position: absolute;
    top: 0;
    right: 42px;
}
.arrows {
    width: 20px;
    height: 26px;
    position: relative;
    overflow: hidden;
    & > img {
        position: absolute;
        top: -270px;
        left: -88px;
        width: 510px;
    }
}
.news-part {
    width: 100%;
    display: flex;
}
.news_content img {
    width: 100%;
    height: 151px;
}
.news_content {
    width: 231px;
    height: 151px;
    position: relative;
}
.news-part .a_Box {
    padding-left: 13px;
}
.a_Box li {
    display: flex;
    margin-bottom: 20px;
    height: 14px;
    align-items: center;
    text-align: left;
    justify-content: center;
}
.dev_line {
    display: inline-block;
    width: 1px;
    height: 200px;
    background: #d7d7d7;
    opacity: 0.5;
}
.pull-left {
    float: left !important;
}
img {
    vertical-align: middle;
}
.us_inner_cen {
    width: 328px;
    padding-left: 29px;
    padding-right: 31px;
}

.clas_space {
    width: 100%;
}
.us_inner_cen a {
    right: 30px;
}
.a_Box a {
    width: 259px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.a_Box1 li {
    display: flex;
    margin-bottom: 20px;
    height: 14px;
    align-items: center;
    justify-content: center;
    text-align: left;
}
.a_Box1 a {
    width: 279px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dev_line {
    display: inline-block;
    width: 1px;
    height: 200px;
    background: #d7d7d7;
    opacity: 0.5;
}
.us_inner_right {
    width: 328px;
    padding-left: 30px;
}
.us_inner_right span {
    width: 101px;
}
.us_inner_right a {
    right: 30px;
}
.mid-title {
    width: 282px;
    height: 44px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 0 auto;
    /* line-height: 62px; */
    margin-top: 111px;
    margin-bottom: 37px;
    text-align: center;
}
</style>
